define('modules/pagenav/sidenav/js/base.js',["app/config","jquery","app/util","templates","waypoints"], function(config,$,util,templates) {
    return {
        name: 'sidenav',
        selectors: false,
        remoteData: [],
        routes: [{
            // TODO - This potentially handles the refreshing of Waypoints, where we no longer need the "pagenavupdate" event
            route: '(news|events|judges|finalists|semi-finalists|results)', // TODO - Ensure/define our routes better
            fn: function(){
                Waypoint.refreshAll();
            },
            extra: true,
            delay: 3500
        }],

        inviews: [],

        initialize: function (data) {

            $.fn.swipeDetector = function (options) {
                // States: 0 - no swipe, 1 - swipe started, 2 - swipe released
                var swipeState = 0;
                // Coordinates when swipe started
                var startX = 0;
                var startY = 0;
                // Distance of swipe
                var pixelOffsetX = 0;
                var pixelOffsetY = 0;
                // Target element which should detect swipes.
                var swipeTarget = this;
                var defaultSettings = {
                // Amount of pixels, when swipe don't count.
                swipeThreshold: 140,
                // Flag that indicates that plugin should react only on touch events.
                // Not on mouse events too.
                useOnlyTouch: false
                };
                
                // Initializer
                (function init() {
                    options = $.extend(defaultSettings, options);
                    // Support touch and mouse as well.
                    swipeTarget.on('mousedown touchstart', swipeStart);
                    $('html').on('mouseup touchend', swipeEnd);
                    $('html').on('mousemove touchmove', swiping);
                })();
                
                function swipeStart(event) {
                    if (options.useOnlyTouch && !event.originalEvent.touches)
                        return;
                    
                    if (event.originalEvent.touches)
                        event = event.originalEvent.touches[0];
                    
                    if (swipeState === 0) {
                        swipeState = 1;
                        startX = event.clientX;
                        startY = event.clientY;
                    }
                }
                
                function swipeEnd(event) {
                    if (swipeState === 2) {
                        swipeState = 0;
                        
                        if (Math.abs(pixelOffsetX) > Math.abs(pixelOffsetY) &&
                            Math.abs(pixelOffsetX) > options.swipeThreshold) { // Horizontal Swipe
                            if (pixelOffsetX < 0) {
                                swipeTarget.trigger($.Event('swipeLeft.sd'));
                                // console.log('Left');
                                if ($(window).width() < 1024) {
                                    $('.sideNav .next').trigger('click');
                                }
                            } else {
                                swipeTarget.trigger($.Event('swipeRight.sd'));
                                // console.log('Right');
                                if ($(window).width() < 1024) {
                                    $('.sideNav .prev').trigger('click');
                                }
                            }
                        } else if (Math.abs(pixelOffsetY) > options.swipeThreshold) { // Vertical swipe
                            if (pixelOffsetY < 0) {
                                swipeTarget.trigger($.Event('swipeUp.sd'));
                                // console.log('Up');
                            } else {
                                swipeTarget.trigger($.Event('swipeDown.sd'));
                                // console.log('Down');
                            }
                        }
                    }
                }
                
                function swiping(event) {
                    // If swipe don't occuring, do nothing.
                    if (swipeState !== 1) 
                        return;
                    
                    
                    if (event.originalEvent.touches) {
                        event = event.originalEvent.touches[0];
                    }
                    
                    var swipeOffsetX = event.clientX - startX;
                    var swipeOffsetY = event.clientY - startY;
                    
                    if ((Math.abs(swipeOffsetX) > options.swipeThreshold) ||
                        (Math.abs(swipeOffsetY) > options.swipeThreshold)) {
                        swipeState = 2;
                        pixelOffsetX = swipeOffsetX;
                        pixelOffsetY = swipeOffsetY;
                        // console.log(pixelOffsetX);
                    }
                }
                
                return swipeTarget; // Return element available for chaining.
            }


            // Render the template
            $('.sideNavWrap:visible').append(templates.sidenav());

            if ($('.sideNavWrap').hasClass('judgesSideNav')) {
                $('.sideNavWrap.judgesSideNav .sideNav').addClass('judges');
            }

            // Wait until nav element sections have been rendered using custom global event
            $('body').on('pagenavupdate', $.proxy(function(){

                // Clear existing template, remove waypoints
                $('.sideNavWrap').empty();
                this.disableWaypoints();

                // Render the new template
                $('.sideNavWrap:visible').append(templates.sidenav());

                if ($('.sideNavWrap').hasClass('judgesSideNav')) {
                    $('.sideNavWrap.judgesSideNav .sideNav').addClass('judges');
                }

                // Set the total count
                $('.sideNav .totalCount').html($('*[data-pagenav="true"]:visible').length);

                if (!$('.sideNavWrap').hasClass('swap-items')) {
                    this.enableWaypoints();
                }
    
                $('#finalistDetail').swipeDetector().on('swipeLeft.sd swipeRight.sd', function(event) {
                    if (event.type === "swipeLeft") {
                        
                    } else if (event.type === "swipeRight") {
                        
                    }
                });

                var triggeredChange = false;

                // Click handlers
                $('.sideNav .prev').on('click', $.proxy(function(e){
                    // TODO fix this, it's hacky

                    if (triggeredChange === false) {
                        triggeredChange = true;

                        var activeIndex = +($('.sideNav .currentCount').text());

                        if ($('#finalistDetail .cardWrap[data-id="'+activeIndex+'"] iframe').length) {
                            $('#finalistDetail .cardWrap[data-id="'+activeIndex+'"] iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                        }

                        if ($('.sideNavWrap').hasClass('swap-items')) {
                            e.preventDefault();
    
                            var activeIndex = +($('.sideNav .currentCount').text());
                            var lastIndex = +($('.sideNav .totalCount').text());
                            var nextIndex = activeIndex + 1;
                            var prevIndex = activeIndex - 1;
    
                            var src = $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"] iframe').attr('src');
    
                            // stop video
                            $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"] iframe').attr('src', src);
    
                            //next not at the end
                            if ($(e.currentTarget).hasClass('next') && ( activeIndex == lastIndex)) {
                                $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
    
                                // TODO maybe not hardcode
                                $('*[data-pagenav="true"][data-finalist="1"]').addClass('active');
                                $('.sideNav .currentCount').text(1);
                            }
                            else if($(e.currentTarget).hasClass('next')) {
                                $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
                                $('*[data-pagenav="true"][data-finalist="'+nextIndex+'"]').addClass('active');
                                $('.sideNav .currentCount').text(nextIndex);
                            }
                            else if ($(e.currentTarget).hasClass('prev') && activeIndex == 1) {
                                $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
                                $('*[data-pagenav="true"][data-finalist="'+lastIndex+'"]').addClass('active');
                                $('.sideNav .currentCount').text(lastIndex);
                            }
                            else if($(e.currentTarget).hasClass('prev')) {
                                $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
                                $('*[data-pagenav="true"][data-finalist="'+prevIndex+'"]').addClass('active');
                                $('.sideNav .currentCount').text(prevIndex);
                            }
    
                        }
    
    
                        else {
                            e.preventDefault();
                            this.disableWaypoints();
    
                            var panelStyle = true; // TODO - Make this config, add back in offsets and animation duration below for false
                            if(panelStyle && $('.sideNavWrap:visible').attr('data-scrollparent')) {
                                $('.sideNavWrap').parents($('.sideNavWrap:visible').attr('data-scrollparent')).css('overflow','scroll');
                            }
    
                            setTimeout($.proxy(function(){
                                if ($('.sideNav .currentCount').html() == 1) {
                                    if ($('.finalistsPageContainer').hasClass('active')) {
                                        var displayHash = '#results/' + $('.sideNav .totalCount').html();
                                        history.pushState(null, null, displayHash);
                                    }
                                    util.scrollElToTop($('*[data-pagenav="true"]:visible').last(),0,1); // Going from the first item to the last
                                } else {
                                    if ($('.finalistsPageContainer').hasClass('active')) {
                                        var displayHash = '#results/' + ($('.sideNav .currentCount').html() - 1);
                                        history.pushState(null, null, displayHash);
                                    }
                                    util.scrollElToTop($('*[data-pagenav="true"]:visible').eq($('.sideNav .currentCount').html()-2),0,1); // Moving to the last item
                                }
                                setTimeout($.proxy(function(){
                                    this.enableWaypoints();
                                    if(panelStyle) {
                                        $('.sideNavWrap').parents($('.sideNavWrap:visible').attr('data-scrollparent')).css('overflow','hidden');
                                    }
                                },this),50);
                            },this),10);
                        }

                        setTimeout(function(e) {
                            triggeredChange = false;
                        }, 1000);
                    }
                },this));
                $('.sideNav .next').on('click', $.proxy(function(e){
                    // TODO fix this, it's hacky

                    if (triggeredChange === false) {
                        triggeredChange = true;

                        var activeIndex = +($('.sideNav .currentCount').text());

                        if ($('#finalistDetail .cardWrap[data-id="'+activeIndex+'"] iframe').length) {
                            $('#finalistDetail .cardWrap[data-id="'+activeIndex+'"] iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                        }

                        if ($('.sideNavWrap').hasClass('swap-items')) {
                            e.preventDefault();
    
                            var activeIndex = +($('.sideNav .currentCount').text());
                            var lastIndex = +($('.sideNav .totalCount').text());
                            var nextIndex = activeIndex + 1;
                            var prevIndex = activeIndex - 1;
    
                            var src = $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"] iframe').attr('src');
    
                            // stop video
                            $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"] iframe').attr('src', src);
    
                            //next not at the end
                            if ($(e.currentTarget).hasClass('next') && ( activeIndex == lastIndex)) {
                                $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
    
                                // TODO maybe not hardcode
                                $('*[data-pagenav="true"][data-finalist="1"]').addClass('active');
                                $('.sideNav .currentCount').text(1);
                            }
                            else if($(e.currentTarget).hasClass('next')) {
                                $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
                                $('*[data-pagenav="true"][data-finalist="'+nextIndex+'"]').addClass('active');
                                $('.sideNav .currentCount').text(nextIndex);
                            }
                            else if ($(e.currentTarget).hasClass('prev') && activeIndex == 1) {
                                $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
                                $('*[data-pagenav="true"][data-finalist="'+lastIndex+'"]').addClass('active');
                                $('.sideNav .currentCount').text(lastIndex);
                            }
                            else if($(e.currentTarget).hasClass('prev')) {
                                $('*[data-pagenav="true"][data-finalist="'+activeIndex+'"]').removeClass('active');
                                $('*[data-pagenav="true"][data-finalist="'+prevIndex+'"]').addClass('active');
                                $('.sideNav .currentCount').text(prevIndex);
                            }
    
                        }
    
    
                        else {
                            e.preventDefault();
                            this.disableWaypoints();
    
                            var panelStyle = true; // TODO - Make this config, add back in offsets and animation duration below for false
                            if(panelStyle && $('.sideNavWrap:visible').attr('data-scrollparent')) {
                                $('.sideNavWrap').parents($('.sideNavWrap:visible').attr('data-scrollparent')).css('overflow','scroll');
                            }
    
                            setTimeout($.proxy(function(){
                                if (( $('.sideNav .currentCount').html() == $('.sideNav .totalCount').html())) {
                                    if ($('.finalistsPageContainer').hasClass('active')) {
                                        var displayHash = '#results/1';
                                        history.pushState(null, null, displayHash);
                                    }
                                    util.scrollElToTop($('*[data-pagenav="true"]:visible').eq(0),0,1); // Going from the last item to the first
                                } else {
                                    if ($('.finalistsPageContainer').hasClass('active')) {
                                        var displayHash = '#results/' +  (parseInt($('.sideNav .currentCount').html(), 10) + 1);
                                        history.pushState(null, null, displayHash);
                                    }
                                    util.scrollElToTop($('*[data-pagenav="true"]:visible').eq($('.sideNav .currentCount').html()),0,1); // Moving to the next item
                                }
                                setTimeout($.proxy(function(){
                                    this.enableWaypoints();
                                    if(panelStyle) {
                                        $('.sideNavWrap').parents($('.sideNavWrap:visible').attr('data-scrollparent')).css('overflow','hidden');
                                    }
                                },this),50);
                            },this),10);
                        }

                        setTimeout(function(e) {
                            triggeredChange = false;
                        }, 1000);
                    }
                },this));
            },this));

            function updateFooter() {
                //$('.sideNav').css('top', window.innerHeight + window.scrollY - 60 + "px");
                //$('.sideNav').css('bottom', '8px');
            }

            var w = $( window ).width();
			// TODO this is MIT2016 specific rework
			/*
            if (w <=767){
                $(document).bind('touchstart', function(e) {
                    if(!$(e.target).parents(".sideNav").length > 0) { // to avoid hiding the footer if it is node 'touched' by the user
                        $('.sideNav').hide();
                    };
                });

                $(document).bind('touchend', function() {
                    //_.defer(function() { // handles waiting until snapback scrolling on mobile devices has finished
                    $('.sideNav').show();
                    // });
                });

                $(document).bind('scroll', function(e) {
                    updateFooter();
                });
            }*/

        },

        enableWaypoints: function() {
            // Add the waypoint listeners
            $('*[data-pagenav="true"]:visible').each($.proxy(function(index,element){
                var inview = new Waypoint({
                    element: $(element),
                    offset: 150,
                    continuous: false,
                    context: ($(element).scrollParent()[0] === document ? $('body') : $(element).scrollParent()),
                    handler: $.proxy(function(direction) {
                        $('.sideNav .currentCount').html($(element).index() + 1);
                    },this)
                });
                this.inviews.push(inview);
            },this));
            Waypoint.refreshAll(); // Refresh all waypoints
        },

        disableWaypoints: function() {
            $.each(this.inviews,function(index,item){
                item.destroy();
            });
            this.inviews = [];
        }

    }
});
