define('modules/navbar/compact/js/base.js',["app/module", "app/config", "jquery", "app/util", "templates", "waypoints", "jquery.livequery"], function (module, config, $, util, templates) {
    return new module({
        name: 'navbar',
        selectors: '.navbarWrap',
        remoteData: [],

        routes: [{
            route: '.*',
            fn: function () {
                $('#mainNavbar').removeClass('shrink');
            },
            extra: true
        }],

        config: {
            hiddenNavBar: true,
            stickySideNavBar: false,
            animatedLogo: true,
            logodelay: false,
            showOnHover: false,
        },

        initialize: function () {

            // this controls the finalist announcement banner on the homepage
            var initialHash = window.location.hash;

            if (initialHash === '#home' || initialHash === '') {
                $('.champions-overlay').addClass('shown');
                $('body').addClass('locked');
            };

            // This controls the finalists overlay
            $('.champions-overlay .js-close-overlay').on('click', function(e) {
                var $self = $(this);

                if ($self.hasClass('btn')) {
                    setTimeout(function() {
                        $('.champions-overlay').removeClass('shown');
                        $('body').removeClass('locked');
                    }, 400);
                } else {
                    $('.champions-overlay').removeClass('shown');
                    $('body').removeClass('locked');
                };
                
            });

            // Apply config
            if ($(this.getSelectors()).attr('data-hiddennav')) {
                this.getConfig().hiddenNavBar = true;
            }

            // Render the template
            $(this.getSelectors()).html(templates.navbar({
                showRegisterLogin: (typeof config.showRegisterLogin == 'undefined' ? true : config.showRegisterLogin)
            }));

            // Handling for activePageTitle text swaps
            $(this.getSelectors()).livequery('.activePageTitle:visible',function() {
                $(this).text(util.getActivePageTitle());
            });

            // Set navbar animations depending on layout type / animation mode
            // TODO - Streamline this process across all navbar modules
            // TODO - Remove the hard references to logo img below, set as module config


            // nav social
            if (this.getConfig().socialExpand) {
                if ($(window).width() < 992) {
                    $('.dashboard-menu-social-share, .dashboard-menu-social-share .animated-heart-icon').click(function (e) {
                        if (e.target !== this)
                            return;
                        $('.dashboard-menu-social-share').addClass('social-expanded-on');
                    });
                    $('.mobile-close-social').click(function () {


                        $('.dashboard-menu-social-share').removeClass('social-expanded-on');
                    });
                }
                else {
                    $('.dashboard-menu-social-share').hover(
                        function () {
                            $(this).addClass('social-expanded-on');
                        }, function () {
                            $(this).removeClass('social-expanded-on');
                        }
                    );
                }
            }

            if (this.getConfig().hiddenNavBar) {
                this.handleHiddenNavBar();
            } else if (this.getConfig().stickySideNavBar) {
                this.handleStickySideNavBar();
            } else {
                // Automatic detection of animation mode for navbar
                if ($(window).width() < 768) {
                    setTimeout($.proxy(this.basicAnimation, this), 1000);
                } else {
                    this.cardPanel();
                }
            }

            if (this.getConfig().logodelay) {
                var delayTime = (this.getConfig().logodelay);
                setTimeout($.proxy(function () {
                    $('.navbar-brand-holder').addClass('navbar-brand');
                }), delayTime);
            }
        },

        methods: {
            // TODO - Figure out not to constrain this context to pageContainer, as that relies on using a specific nav layout
            basicAnimation: function () {
                $('.pageContainer .section:first-of-type').waypoint(function (direction) {
                    $('#mainNavbar').toggleClass('shrink', (direction == 'down'));
                }, {
                    offset: -100,
                    context: '.pageContainer'
                });
                $('.pageContainer .section:last-of-type').waypoint(function (direction) {
                    $('#mainNavbar').toggleClass('shrink', (direction == 'up'));
                }, {
                    offset: 100,
                    context: '.pageContainer'
                });
            },
            cardPanel: function () {
                // Navbar shrink state on panel snap events
                $('body').on('cardsnapfinish', function (event, panel) {
                    if ($(panel).parents('.pageContainer').hasClass('enableNavAni') || util.device.mobile()) {
                        $('#mainNavbar')[($(panel).index() == 0 || $(panel).index() + 1 == $(panel).parent().children().length) ? 'removeClass' : 'addClass']('shrink');
                    }
                });
            },
            handleStickySideNavBar: function () {
                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', util.minScrollSpeed($.proxy(function (event) {

                    var scrollTop = $(window).scrollTop();

                    //scroll down
                    if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                        $('.dashboard-menu-social-share').removeClass('social-expanded-on');

                        if (scrollTop > 500) {
                            $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
                            setTimeout($.proxy(function () {
                                $('.navbarWrap').addClass('topZIndex')
                            }), 1000);
                            $('.dashboard-menu-container').removeClass('slide-from-right').addClass('stickySideNavBar');
                        }
                    }

                    //scroll up
                    else {
                        if (scrollTop > 500) {
                            $('.dashboard-menu-container').addClass('slide-from-right');
                        }
                        else {
                            $('.dashboard-menu-container').removeClass('slide-from-right');
                            setTimeout($.proxy(function () {
                                $('.dashboard-menu-container').removeClass('stickySideNavBar');
                                $('.navbarWrap').removeClass('slideDownIn slideUpOut topZIndex');
                            }), 10);
                        }
                    }

                    //at top
                    if (scrollTop == 0) {
                        $('.dashboard-menu-container').removeClass('stickySideNavBar slide-from-right');
                        $('.navbarWrap').removeClass('slideDownIn slideUpOut topZIndex');
                    }

                    this.lastScrollTop = scrollTop;
                }, this)));
            },
            handleHiddenNavBar: function () {
                $(window).on('mousewheel DOMMouseScroll onmousewheel touchmove scroll', $.throttle(100, util.minScrollSpeed($.proxy(function (event) {
                    // TODO test this, specific to navMap
                    if ($('.navbarWrap').hasClass('closed')) {
                    }
                    else {

                        var scrollTop = $(window).scrollTop();
                        if (scrollTop > this.lastScrollTop && scrollTop > 0) {
                            $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
                            if (this.getConfig().animatedLogo) {
                                if (!$('.navbar-brand').hasClass('logo-animate-closed')) {
                                    $('.navbar-brand').addClass('logo-animate-closed');
                                    $('.navbar-brand-sub').removeClass('top').addClass('logo-animate-closed');
                                }
                            }
                            else {
                                $('.navbar-brand').removeClass('nb-slidedown').addClass('nb-slideup')
                            }
                        }
                        else {
                            if(!this.getConfig().showOnHover) {
                                $('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn mobile-transition');
                                if (!this.getConfig().animatedLogo) {
                                    $('.navbar-brand').removeClass('nb-slidedown').addClass('nb-slideup');
                                }
                            }
                            if (scrollTop <= 100) {
                                if (this.getConfig().animatedLogo) {
                                    $('.navbar-brand').removeClass('logo-animate-closed');
                                    $('.navbar-brand-sub').addClass('top');
                                } else {
                                    $('.navbar-brand').removeClass('nb-slideup').addClass('nb-slidedown');
                                }
                                $('.navbarWrap').removeClass('slideDownIn slideUpOut topZIndex');
                                $('.navbarWrap').removeClass('mobile-transition');
                            }
                        }

                        this.lastScrollTop = scrollTop;
                    }
                }, this))));

            }
        }
    });
});
