define('modules/news/basic/js/base.js',["app/config","jquery","app/util","templates"], function(config,$,util,templates) {
    return {
        name: 'news',
        selectors: false,
        remoteData: [config.urls.news],

        initialize: function (data) {

            $.each(data,$.proxy(function(index,record){
                // Add each news post to the main container
                $('.newsContainer').append(templates.newspost(record));
                // Set the first three news posts on the menu main
                if(index < 3) {
                    $('#mainMenu .newsContent').append(templates.newsmenu(record));
                }
                // Set the first four titles on the news page
                if(index < 4) {
                    var titleEl = $('<div class="col-md-1 col-xs-3"></div>');
                    var date = new Date(record.date);
                    titleEl.html('<div class="titleWrap"><span>'+(date.toDateString().split(' ')[1])+'</span><span>'+(date.getDate())+'</span><span>'+String(date.getFullYear())+'</span></div>');
                    titleEl.attr('data-news',record.id);
                    $('#news .newsTitleWrap').append(titleEl);
                }
            },this));
            $('#news .newsTitleWrap > div').first().addClass('active');

            $('.newsContainer').on('click', '.readMoreBtn', function(e) {
                e.preventDefault();
                var $target = $(e.target);
                var $article = $target.is('article') ?
                    $target :
                    $target.parents('article');

                var $readMoreBtn = $('.newsContainer .readMoreBtn'),
                    isMobile = !$readMoreBtn.is(':visible'),
                    shouldToggle = false;

                if(isMobile){
                    // the user clicked the article, the button is not visible(mobile), toggle the news
                    shouldToggle = true;
                }
                else if($target.hasClass('readMoreBtn')){
                    // the user clicked the button, which is visible(desktop), toggle the news
                    shouldToggle = true;
                }
                else{
                    // the user clicked the article, the button is visible(desktop), do nothing
                    shouldToggle = false;
                }

                if(shouldToggle) {
                    $article.find('.postContent').slideToggle(300, function(){
                    }).toggleClass('open');
                    $article.find($readMoreBtn).siblings('.postDescription').toggle();
                    $article.find($readMoreBtn).text($article.find($readMoreBtn).hasClass('btnClose') ? 'Read' : 'Close');
                    $article.find($readMoreBtn).toggleClass('btnClose');
                };

                return false;
            });

            // Handle news hashtag
            // TODO - This needs to be refactored into a route method
            if ( location.hash.length >= 2 ) {
                // Fetch the selected news item
                var newsItem = $('#news [data-news="' + location.hash.substr(1) + '"]');
                if (newsItem.length > 0) {
                    newsItem.find('.readMoreBtn').trigger('click');
                    var newsScrollBody = ( $(window).width() >= 768 ? $('.newsPostCol') : $(window) );
                    $(window).scrollTop(0);
                    newsScrollBody.scrollTop( newsItem.offset().top - $('#mainNavbar').height() - 20 );
                }
            }

            $('body').trigger('pagenavupdate');

        }
    }
});
