define('modules/finalists/sidebyside/js/base.js',["app/config", "app/util", "jquery", "templates", "skrollr", "waypoints"], function (config, util, $, templates, skrollr) {
    return {
        name: 'judges',
        selectors: '.finalistContainer',
        remoteData: [config.urls.finalists],
        routes: [{
            route: 'results/(\\d+)',
            fn: function (hash) {
                $('#finalists .judgebox[data-judge="' + hash.split('/')[1] + '"]').trigger('click');
            },
            extra: true,
            delay: 2500
        }, {
            route: 'results',
            fn: 'showLeftSide',
            extra: true,
            delay: 500
        }, {
            // route: 'results/(\\d+)',
            // fn: function (hash) {
            //     var finalistId = hash.split('/')[1];
            //     console.log(finalistId);
            //     this.renderFinalistDetail(finalistId);
            // },
            // extra: true,
            // delay: 2500
        }],

        leftWaypoints: [],

        config: {
            rightPanelLayout: true,
            showTypeBar: false
        },


        initialize: function (data) {

            this.data = data.sort(function(a, b) {
                if(a.country < b.country) { return -1; }
                if(a.country > b.country) { return 1; }
                if(a.firstName < b.firstName) { return -1; }
                if(a.firstName > b.firstName) { return 1; }
                return 0;
            });

            // Figure out the grouping for judges and render the page template
            this.renderTemplates(data);

            // top buttons scroll to section

            // TODO Set this up as config option with selector
            // cloning this container because we need it in the intro section
            //var btnContainerClone = $('.introBtnContainer').clone(true);
            //$('.introBtnContainer').remove();
            //$('.judgesPageContainer .btn-clone-container').html(btnContainerClone);

            // Add the click handlers to judgeboxes
            $('.finalistContainer .judgebox').on('click', $.proxy(this.onFinalistBoxClick, this));

            // Init Skrollr
            this.initSkrollr();
            var lastScrollTop = 0;
            // Bottom toggle bar navigation, this mostly chains to other methods
            $('.finalistContainer .judgeTypeBar span, .typeIcon').click($.proxy(function (e) {
                e.preventDefault();

                var el = $(e.delegateTarget),
                    group = $(el).attr('data-judgegroup');

                $($('.finalistContainer .judgeGroup[data-judgegroup="' + group + '"]').scrollParent()).animate({
                    scrollTop: $('.finalistContainer .judgeGroup[data-judgegroup="' + group + '"]').offset().top
                }, 500);
                Waypoint.refreshAll();

                /*//var st = $('.judgeGroup[data-judgegroup="'+group+'"]').scrollTop();
                var st = $('.judgeGroup[data-judgegroup="'+group+'"]').offset().top;
                //var lastScrollTop = 0;
                if($(el).hasClass('backToggle')) {
                    this.showLeftSide(group);
                } else if($('#judgesDetail:visible').length > 0) {
                    this.showRightSide(group);
                } else {

                    // We are still on the left side and scrolling within
                    if (st > lastScrollTop){
                    	// if we're scrolling down less top padding
						util.scrollElToTop($('.judgeGroup[data-judgegroup="'+group+'"]'),-40);
					} else {
						// if we're scrolling up more top padding
						util.scrollElToTop($('.judgeGroup[data-judgegroup="'+group+'"]'),-220);
					}
					lastScrollTop = st;

                    //util.scrollElToTop($('.judgeGroup[data-judgegroup="'+group+'"]'),-40);
                    Waypoint.refreshAll();
                }*/
            }, this));

            // Waypoint for toggling the active tab for judges group
            // $('.judgeGroup').each($.proxy(function (index, el) {
            //     var waypoint = new Waypoint({
            //         offset: 200,
            //         element: $(el),
            //         handler: $.proxy(function () {
            //             $('.judgeTypeBar span').toggleClass('enabled', false);
            //             $('.judgeTypeBar span[data-judgegroup="' + $(el).attr('data-judgegroup') + '"]').toggleClass('enabled', true);
            //         }, this)
            //     });
            //     this.leftWaypoints.push(waypoint);
            //     // Secondary waypoint with 1px offset, due to scrollTo handling in the up direction with an exact offset (see https://github.com/imakewebthings/waypoints/issues/346#issuecomment-65501126)
            //     // TODO - P2 Make a Waypoint adapter with this in it and other common use cases
            //     var waypoint = new Waypoint({
            //         offset: 199,
            //         element: $(el),
            //         handler: $.proxy(function (direction) {
            //             if (direction == 'up') {
            //                 $('.judgeTypeBar span').toggleClass('enabled', false);
            //                 $('.judgeTypeBar span[data-judgegroup="' + $(el).attr('data-judgegroup') + '"]').toggleClass('enabled', true);
            //             }
            //         }, this)
            //     });
            //     this.leftWaypoints.push(waypoint);
            // }, this));


            // Apply hashchange for direct judge routing
            // $(window).hashchange($.proxy(function(){
            //      if(location.hash.split('/')[0].substr(1) == 'results') {
            //          skrollr.get().refresh();
            //          if(location.hash.split('/')[1]) {
            //              // Get existing hash and use it to trigger the click
            //              $('#finalists .judgebox[data-judge="'+location.hash.split('/')[1]+'"]').trigger('click');
            //          } else {
            //              this.showLeftSide();
            //          }
            //      }
            //  },this));


            this.mobileToggleBar();
            this.refreshAnim();

            // this.loadJudgeSample(data);
        },

        gotojudgesgroupone: function () {
            $('.navbarWrap').removeClass('.slideDownIn').addClass('slideUpOut');
            $('html, body').animate({
                scrollTop: $('.finalistContainer .load_judges_g1_h3').offset().top - 100
            }, 500);
        },
        gotojudgesgrouptwo: function () {
            $('.navbarWrap').removeClass('.slideDownIn').addClass('slideUpOut');
            $('html, body').animate({
                scrollTop: $('.finalistContainer .load_judges_g2_h3').offset().top - 100
            }, 500);
        },

        renderFinalistDetail: function(finalistId) {

            if (!this.finalistsById) {
                var finalistsById = {};
                $.each(this.data, $.proxy(function (index, item) {
                    item.originalIndex = index;
                    finalistsById[item.id] = item;
                }, this));
                this.finalistsById = finalistsById;
            }
            var finalistItem = this.finalistsById[finalistId];
            $('#finalists .judgebox[data-judge="'+ finalistItem.id +'"]').trigger('click');
        },



        // Groups and renders the template data
        renderTemplates: function (data) {
            var finalistsGroup = [],
                templateData = {
                    finalistsGroup: []
                };
            $.each(data, function (index, record) {
                if (!finalistsGroup[record.type]) {
                    finalistsGroup[record.type] = {
                        name: record.type,
                        title: record.shortDescription,
                        cms: record.cms,
                        records: []
                    };
                }
                finalistsGroup[record.type].records.push(record);
            });
            for (var key in finalistsGroup) {
                templateData.finalistsGroup.push(finalistsGroup[key]);
            }
            this.templateData = templateData;
            $('.finalistContainer').append(templates.finalistspage({
                data: templateData,
                config: this.config
            }));
        },

        // Handle for when a judge is clicked on, this can also be manually triggered
        onFinalistBoxClick: function (e) {

            e.preventDefault();
            $.each(this.leftWaypoints, function () {
                this.disable();
            });

            var jGroup = $(e.delegateTarget).attr('data-judgegroup'),
                jId = $(e.delegateTarget).attr('data-judge');

            var displayHash = '#results/' + jId;

            history.pushState(null, null, displayHash);

            if (this.config.rightPanelLayout) {
                this.showRightSide(jGroup);

                $('#finalistDetail').find('.tplWrapper').css('overflow', 'scroll');
                util.scrollElToTop($('#finalistDetail').find('*[data-pagenav][data-id="' + jId + '"]'), 0, 1);
                setTimeout($.proxy(function () {
                    $('body').trigger('pagenavupdate');
                    $('#finalistDetail').find('.tplWrapper').css('overflow', 'hidden');
                }, this), 5);

            } else {
                $('#finalistDetail').show();
            }

            setTimeout($.proxy(function () {
                $('body').addClass('judgeDetailOpen');
                $('.navbarWrap').addClass('hidden');
                if (skrollr.get()) {
                    skrollr.get().refresh(); // Refresh skrollr for new height layout changes
                }
                setTimeout(function () {
                    //$('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');
                }, 60); // TODO - We need to address this in a better way to handle the temporary flash, attempt to reduce timeout
            }, this), 50);

            // Update the text on the judges bar
            this.resetToggleBar(jGroup, true);
            this.mobileToggleBar();

            $('#finalistDetail .judgeInfoGroup').toggle(false);
            $('#finalistDetail .judgeInfoGroup[data-judgegroup="' + jGroup + '"]').toggle(true);

            if (!this.config.rightPanelLayout) {
                if ($(window).width() >= 768) {
                    if ($('.finalistContainer .judgeinfoWrap[data-judge="' + jId + '"]').offset().top < 200) {
                        util.scrollElToTop($('.finalistContainer .judgeinfoWrap[data-judge="' + jId + '"]'), -220);
                    } else {
                        util.scrollElToTop($('.finalistContainer .judgeinfoWrap[data-judge="' + jId + '"]'), -40);
                    }
                } else {
                    util.scrollElToTop($('.finalistContainer .judgeinfoWrap[data-judge="' + jId + '"]'), -20);
                }
                $('body').trigger('pagenavupdate');
            }

            return false;

        },

        initSkrollr: function () {
            // Init skrollr, only one instance will exist so this can and should be called from all files requiring it
            skrollr.init({
                smoothScrollingDuration: 50,
                forceHeight: false,
                mobileCheck: function () {
                    return false;
                }, // Uncomment mobileCheck for mobile debugging
                constants: {
                    // Viewport height - dynamic constant
                    vph: function () {
                        return $('html').height() || $('#form1').height();
                    },
                    mid: function () {
                        return $('html').height() / 2 || $('#form1').height() / 2;
                    },
                    35: function () {
                        return Math.round($('html').height() * 0.35) || Math.round($('#form1').height() * 0.35);
                    }
                }
            });
        },

        resetToggleBar: function (active, rightside) {

            if (rightside && this.config.rightPanelLayout) {
                $('.finalistContainer .judgeTypeBar').hide();
            } else {
                $('.finalistContainer .judgeTypeBar').show();
            }

            $('.nav .typeIcon').each(function () {
                $(this).text($(this).attr('data-judgegroup'));
            });

            $('.finalistContainer .judgeTypeBar span').removeClass('backToggle enabled view');
            $('.finalistContainer .judgeTypeBar span').each(function () {
                $(this).text($(this).attr('data-judgegroup'));
                if (active && active == $(this).attr('data-judgegroup') && !rightside) {
                    $(this).addClass('enabled');
                }
                if (active && active == $(this).attr('data-judgegroup') && rightside) {
                    $(this).addClass('backToggle');
                } else if (rightside) {
                    $(this).addClass('view');
                }
            });
        },

        // Moving back to the left side
        showLeftSide: function (judgegroup) {


            // Reset elements
            $('body').removeClass('judgeDetailOpen locked');
            $('.navbarWrap').removeClass('slideUpOut').addClass('slideDownIn');

            // without changing the css to get rid of the borderbox from judgegroup
            // on judge detail view
            $('#finalists .judgeGroup').css('left', '0px'); // TODO - Move to CSS


            this.resetToggleBar(judgegroup);
            this.mobileToggleBar();

            $.each(this.leftWaypoints, function () {
                this.enable();
            });

            var scrollBackToEl = $('.finalistContainer .judgeGroup[data-judgegroup="' + (judgegroup || '') + '"]');
            setTimeout($.proxy(function () {
                if (this.config.rightPanelLayout) {
                    this.refreshAnim();
                } else {
                    $('#finalistDetail').hide();
                    this.refreshAnim();
                    if ($(window).width() >= 768) {
                        util.scrollElToTop((scrollBackToEl.length > 0 ? scrollBackToEl : false), -40);
                    } else {
                        util.scrollElToTop((scrollBackToEl.length > 0 ? scrollBackToEl : false), -100);
                    }
                }
            }, this), 50);

            if (this.config.rightPanelLayout) {
                setTimeout($.proxy(function () {
                    $('#finalistDetail').empty().hide();
                }, this), 1000);
            }

        },

        // Staying on the right side and swapping content
        showRightSide: function (group) {

            $.each(this.leftWaypoints, function () {
                this.disable();
            });

            this.resetToggleBar(group, true);
            this.mobileToggleBar();

            if (this.config.rightPanelLayout) {
                var groupData = null;
                $.each(this.templateData['finalistsGroup'], function (i, d) {
                    if (d.name == group) {
                        groupData = d.records;
                    }
                });
                $('#finalistDetail').html(templates.sharedcardFinalist({
                    records: $.map(groupData, function (rec) {
                        return {
                            id: rec.id,
                            name: rec.name,
                            title: rec.title,
                            shortDescription: rec.shortDescription,
                            video: rec.videoUrl,
                            detailsImage: rec.detailsImageUrl,
                            detailsImageAltText: rec.detailsImageAltText,
                            span: rec.date,
                            type: rec.type,
                            country: rec.country,
                            image: rec.image,
                            content: rec.longDescription
                        }
                    })
                }));

                $('body').addClass('locked').trigger('pagenavupdate');
                $('#finalistDetail').show();
                $('#finalistDetail').find('.icon-close').on('click', $.proxy(function (e) {
                    e.preventDefault();

                    var displayHashInitial = window.location.href.split('/')[0] + '/#results';
                    history.pushState(null, null, displayHashInitial);

                    $('#finalistDetail').find('.tplWrapper').removeClass('open');
                    $('.navbarWrap').removeClass('hidden');
                    this.showLeftSide(group);
                }, this));
                setTimeout(function () {
                    $('#finalistDetail').find('.tplWrapper').addClass('open');
                }, 25);

            } else {
                // Hide the other sections, and show the new active section
                $('#finalistDetail .judgeInfoGroup').hide();
                $('#finalistDetail .judgeInfoGroup[data-judgegroup="' + group + '"]').show();

                // Scroll to and refresh
                $('html,body').animate({
                    scrollTop: -40
                }, 500, 'swing');

                //$('#judgesDetail .nav .currentCount').text(1);
                this.refreshAnim();
                setTimeout(function () {
                    $('body').trigger('pagenavupdate');
                }, 600);

            }

            $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut');

        },

        mobileToggleBar: function () {
            $('.finalistContainer .judgeTypeBar span').each(function () {
                var w = $(window).width();
                if (w < 768) {
                    //$('.judgeTypeBar span').each(function(){
                    var text = $(this).text();
                    var words = $(this).text().split(' ');
                    var html = '';
                    $.each(words, function () {
                        html += this.substring(0, 1);
                    });
                    $(this).html(html);
                    //});
                }
            });


            $('.nav .typeIcon').each(function () {
                var w = $(window).width();
                if (w < 768) {
                    //$('.judgeTypeBar span').each(function(){
                    var text = $(this).text();
                    var words = $(this).text().split(' ');
                    var html = '';
                    $.each(words, function () {
                        html += this.substring(0, 1);
                    });
                    $(this).html(html);
                    //});
                }
            });
        },

        // Refresh animation states
        refreshAnim: function () {
            Waypoint.refreshAll();
            if (skrollr.get()) {
                skrollr.get().refresh();
            }
        },

        loadJudgeSample: function (data) {
            if ($('.finalistContainer .judgeSample').length == 0) {
                return;
            }
            // $('.judgeSample').html(templates.judgesample(util.getRandomRecords(data, 8)));
            $('.finalistContainer .judgeSample').html(templates.judgesample(data));
            $('.finalistContainer .judgeSample .item').first().addClass('active');

            $('.finalistContainer .judgeSample').find('.carousel').carousel({
              interval: 8000
            });
            
            util.bootstrapNumberCarousel('.judge-sample-carousel');


            $('.finalistContainer .judgeSample .judgebox').on('click', $.proxy(function (e) {
                e.preventDefault();
                window.location.hash = 'judges/' + $(e.currentTarget).attr('data-judge');
            }, this));
        }
    }
});

