define('modules/sponsors/sliderbar/js/base.js',["app/module","app/config","jquery","app/util","templates","bootstrap/carousel"], function(module,config,$,util,templates) {
    return new module({
        name: 'sponsors',
        selectors: '.sponsorBar',
        remoteData: [config.urls.sponsors],

        initialize: function (data) {
            $(this.getSelectors()).html(templates.sponsorsliderbar(data));
            $(this.getSelectors()).find('.carousel').carousel();

            if ($( window ).width() < 768) {
                $(this.getSelectors()).find('.item.placeholder').remove();
            }

        },

        methods: {

        }
    });
});
