define('modules/animations/scroll/js/base.js',["app/config","jquery","app/util","jquery.livequery"], function(config,$,util) {
    return {
        name: 'scroll',
        selectors: false,
        remoteData: [],

        initialize: function() {

            // Add scroll link handlers
            $('.downArrow').click(function(){
                $('html, body').animate({
                    scrollTop: $(window).scrollTop()+($(window).height()*0.9)
                }, 500);
                return false;
            });

            // Any elements with "data-scrollto" attribute with a valid selector, when clicked will scroll to that element
            $('body').livequery('*[data-scrollto]',function() {
                $(this).click(function (e) {
                    e.preventDefault();
                    if ($('body').hasClass('spappcard')) {
                        $('body').trigger('snaptocard', $($(this).attr('data-scrollto')));
                    } else {
                        if ($(this).attr('data-scrollto').length > 0) {
                            util.scrollElToTop($($(this).attr('data-scrollto')), ($(this).attr('data-scrolloffset') || 0));
                        }
                    }
                });
            });

            // Section smoothed linking
            $('.smoothBtn').click(function(e){
                e.preventDefault();
                var linkTop = $(this).attr('href');
                if (linkTop) {
                    $('body').animate({scrollTop: $(linkTop).offset().top});
                }
            });

            // Stop overscroll on all classed elements
            $('body').livequery('.stopOver',function(){
                util.preventOverscroll($(this));
            },function(){
                util.preventOverscroll($(this),false);
            });

            $('body').livequery('.js-learn-more-btn',function() {
                $(this).click(function (e) {
                    e.preventDefault();
                    if ($(this).attr('data-learnmore-scrollto').length > 0) {
                        $('.navbarWrap').removeClass('slideDownIn').addClass('slideUpOut')
                        var scrollTarget = $(this).attr('data-learnmore-scrollto');
                        $('html,body').animate({
                            scrollTop: $(scrollTarget).offset().top - 50
                        }, 1000);
                    }
                });
            });
        }
    }
});
