define('templates',['handlebars'], function(Handlebars) {

var glob = ('undefined' === typeof window) ? global : window,

Handlebars = glob.Handlebars || require('handlebars');

this["JST"] = this["JST"] || {};

Handlebars.registerPartial("finalistbox", this["JST"]["finalistbox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"judgebox col-xs-6 col-sm-6 col-md-4 col-lg-3\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"judgethumb\">\n		<img src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" border=\"0\">\n		<div class=\"thumb-overlay\">\n			<span class=\"judgegroup\">"
    + alias4(((helper = (helper = helpers.country || (depth0 != null ? depth0.country : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"country","hash":{},"data":data}) : helper)))
    + "</span>\n			<div class=\"judge-info\">\n				<span class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n				<span class=\"title\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</span>\n				<span class=\"read-bio\">Read More <i class=\"right-arrow next material-icons\">play_arrow</i></span>\n			</div>\n		</div>\n	</div>\n</a>";
},"useData":true}));

Handlebars.registerPartial("finalistgroup", this["JST"]["finalistgroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.finalistbox,depth0,{"name":"finalistbox","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<article class=\"judgeGroup border-box\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"container\">\n        <div class=\"judgeIcon visible-xs\"></div>\n        <h3 class=\"retired-load_finalists_g1_h3\">Meet the Champions</h3>\n        <div class=\"textColumn retired-load_finalists_g1_content\">\n            <p>Our nineteen Mission Innovation Champions—one from each participating country and one from the European Commission (on behalf of the European Union)--are individuals with a track record of progressing creative new ideas that can drive the pace and scale of the clean energy revolution. They work in a variety of fields and industries but all are people who are inventing the products and services of the future or discovering the science that underpins them. The Mission Innovationn Champions were introduced and celebrated at the fourth Mission Innovation ministerial meeting in Vancouver, Canada in May 2019.<br></p>\n        </div>\n    </div>\n    <div class=\"container "
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_detail\" style=\"position:relative;\">\n        <div class=\"judgeGridContainer\">\n            <div class=\"judgesGrid row\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</article>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("finalistinfo", this["JST"]["finalistinfo"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeinfoWrap border-box\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n	<div class=\"row judge-detail\">\n		<div class=\"col-md-12\">\n			<h2 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h2>\n			<h3>"
    + alias4(((helper = (helper = helpers.country || (depth0 != null ? depth0.country : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"country","hash":{},"data":data}) : helper)))
    + "</h3>\n			<div class=\"video-wrap\">Video</div>\n			<h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>\n			<p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n		</div>\n	</div>\n</div>			";
},"useData":true}));

Handlebars.registerPartial("finalistinfogroup", this["JST"]["finalistinfogroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.finalistinfo,depth0,{"name":"finalistinfo","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"row judgeInfoGroup\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"col-md-12 judgeTextContainer\">\n        <div class=\"judgeInner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"nav\">\n		<span class=\"backToggle typeIcon visible-xs\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n		<div class=\"sideNavWrap judgesSideNav\"></div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("judgebox", this["JST"]["judgebox"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"judgebox col-xs-6 col-sm-6 col-md-3\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"judgethumb\">\n		<img src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" border=\"0\">\n		<div class=\"thumb-overlay\">\n			<span class=\"judgegroup\">"
    + alias4(((helper = (helper = helpers.country || (depth0 != null ? depth0.country : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"country","hash":{},"data":data}) : helper)))
    + "</span>\n			<div class=\"judge-info\">\n				<span class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n				<span class=\"title\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</span>\n				<span class=\"read-bio\">Read Bio <i class=\"right-arrow next material-icons\">play_arrow</i></span>\n			</div>\n		</div>\n	</div>\n</a> ";
},"useData":true}));

Handlebars.registerPartial("judgegroup", this["JST"]["judgegroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgebox,depth0,{"name":"judgebox","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<article class=\"judgeGroup border-box\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"container\">\n        <div class=\"judgeIcon visible-xs\"></div>\n        <h3 class=\"retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_h3\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n        <div class=\"textColumn retired-load_"
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_content\">\n            <p>"
    + ((stack1 = ((helper = (helper = helpers.desc || (depth0 != null ? depth0.desc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"desc","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n        </div>\n    </div>\n    <div class=\"container "
    + alias4(((helper = (helper = helpers.cms || (depth0 != null ? depth0.cms : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cms","hash":{},"data":data}) : helper)))
    + "_detail\" style=\"position:relative;\">\n        <div class=\"judgeGridContainer\">\n            <div class=\"judgesGrid row\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</article>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("judgeinfo", this["JST"]["judgeinfo"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"judgeinfoWrap border-box\" data-judge=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n	<div class=\"row judge-detail\">\n		\n		<div class=\"col-md-3 col-md-offset-1\">\n			<img src=\""
    + alias4(((helper = (helper = helpers.thumbnail || (depth0 != null ? depth0.thumbnail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\" />\n		</div>\n		<div class=\"col-md-7\">	\n			<h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>					\n			<h4>"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h4>					\n			<p>"
    + ((stack1 = ((helper = (helper = helpers.longDescription || (depth0 != null ? depth0.longDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"longDescription","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n		</div>\n		\n	</div>		\n</div>			";
},"useData":true}));

Handlebars.registerPartial("judgeinfogroup", this["JST"]["judgeinfogroup"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfo,depth0,{"name":"judgeinfo","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"row judgeInfoGroup\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"col-md-12 judgeTextContainer\">\n        <div class=\"judgeInner\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div class=\"nav\">\n		<span class=\"backToggle typeIcon visible-xs\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n		<div class=\"sideNavWrap judgesSideNav\"></div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("scoringintro", this["JST"]["scoringintro"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return " <div class=\"section pushSection layout-auto-height angleBottomRight\" data-bgimage=\"scoring-intro\">\n	<div class=\"introSection container\" data-title=\"\">\n		<!--<div class=\"load_scoring\">-->\n		<div class=\"row center-height\">\n			<div class=\"col-md-10 col-md-offset-1 content load_scoring_card1\">\n				<h1>Scoring Process</h1>\n				<h2>A careful selection of judges grouped into two categories; the Evaluation Panel and the Selection Committee.</h2>\n				<a class=\"intro-section-btn\" data-scrollto=\".scoreQuestion.section\">Interact with the Rubric<i class=\"icon-icon-arrow-down-rounded\"></i></a>		\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true}));

Handlebars.registerPartial("scoringitems", this["JST"]["scoringitems"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "						<div class=\"tick tickTop\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\"></div>\n						<div class=\"tick tickBottom\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"scoreQuestion section pushSection solid-gray-bg\" data-title=\"\" data-bgimage=\"scoring-body\">\n	<div class=\"container\">\n		<div class=\"row center-height\">\n			<a class=\"sbtn scoreCommentBtn primaryBlankBtn hidden-xs\">Leave Comments</a>\n			<h3 class=\"hidden-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n			<div class=\"text-center\">\n				<div class=\"helpText\"><div class=\"inner\"><h5>Help</h5>"
    + alias4(((helper = (helper = helpers.helpText || (depth0 != null ? depth0.helpText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpText","hash":{},"data":data}) : helper)))
    + "</div></div>\n				<div class=\"commentsText text-center\">\n					<textarea placeholder=\"Comments (minimum 50 characters): Explain your score and provide feedback to the participant.\"></textarea>\n					<div class=\"hidden-xs\">\n						<a class=\"btn\" href=\"#\">Submit Comments</a>\n					</div>\n				</div>\n			</div>\n			<div class=\"scoreHeader\">\n				<div class=\"scoreCounter hidden-xs\">\n					<span class=\"current\">"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"total\">"
    + alias4(((helper = (helper = helpers.total || (depth0 != null ? depth0.total : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<div class=\"scoreCounter scoreNav\">\n					<span class=\"prev\"><i class=\"icon-arrow-left-full\"></i></span>\n					<span class=\"next\"><i class=\"icon-arrow-right-full\"></i></span>\n				</div>\n				<div class=\"scoreQuestion\">\n					<span class=\"visible-xs\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"question-text\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</span>\n					<span class=\"help-question\">\n						<div class=\"icon-icon-help1\"></div>\n					</span>\n					<div class=\"icon-icon-close\"></div>\n				</div>\n			</div>\n			<div class=\"scoreDescription\">\n				<span></span>\n			</div>\n			<div class=\"scoreValue\">\n				<span class=\"valfollow\"></span>\n				<span class=\"valBorderLeft hidden-xs\"></span>\n				<span class=\"valBorderRight hidden-xs\"></span>\n			</div>\n			<div class=\"scoreSliderContainer\">\n				<div class=\"sliderTicks\">\n			    	<span class=\"visible-xs anchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			    	<span class=\"visible-xs anchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<span class=\"traitEndL\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n				<div class=\"scoreSlider\">\n					<input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n				</div>\n				<span class=\"traitEndR\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n		</div>\n		<!--<a class=\"btn scoreCommentBtn primaryBlankBtn hidden-xs hidden-sm \">Leave Comments</a>-->\n	</div>\n</div>";
},"useData":true,"useDepths":true}));

Handlebars.registerPartial("scoringlastcard", this["JST"]["scoringlastcard"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"lastCard section pushSection register layout-auto-height angleTopLeft\" data-bgimage=\"endcard\">\n\n</div>\n";
},"useData":true}));

Handlebars.registerPartial("sidebarbottommenu", this["JST"]["sidebarbottommenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n				<li><span>"
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</span></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sidebar-inner-list inner-closed\">\n	<span class=\"sidebar-inner-back\">\n		<div class=\"sidebar-icon pe-7s-left-arrow\"></div>\n		<div class=\"sidebar-label\">Back</div>\n	</span>\n	<ul>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</div>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebaritem", this["JST"]["sidebaritem"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "a";
},"3":function(container,depth0,helpers,partials,data) {
    return "div";
},"5":function(container,depth0,helpers,partials,data) {
    return "sidebar-item-selected";
},"7":function(container,depth0,helpers,partials,data) {
    return "sidebar-item-nested";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    return "target=\"_blank\"";
},"13":function(container,depth0,helpers,partials,data) {
    return "sidebar-neverwrap";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "			<div class=\"sidebar-item-data data-"
    + container.escapeExpression(((helper = (helper = helpers.dataName || (depth0 != null ? depth0.dataName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dataName","hash":{},"data":data}) : helper)))
    + "\"></div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebartopmenu,depth0,{"name":"sidebartopmenu","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " class=\"sidebar-item "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isSelected : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.newtab : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n	<div class=\"sidebar-container "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.neverWrap : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n		<div class=\"sidebar-icon "
    + alias4(((helper = (helper = helpers.iconClass || (depth0 != null ? depth0.iconClass : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconClass","hash":{},"data":data}) : helper)))
    + "\"></div>\n		<div class=\"sidebar-label "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n			"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.dataName : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("sidebartopmenu", this["JST"]["sidebartopmenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n			<li><span>"
    + container.escapeExpression(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "</span></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"link","hash":{},"data":data}) : helper)))
    + "\">";
},"4":function(container,depth0,helpers,partials,data) {
    return "<div class=\"sidebar-inner-link\">";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebarbottommenu,depth0,{"name":"sidebarbottommenu","data":data,"indent":"\t\t\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"10":function(container,depth0,helpers,partials,data) {
    return "</div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<ul class=\"sidebar-tier1 sidebar-list\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>";
},"usePartial":true,"useData":true}));

Handlebars.registerPartial("timelineinfo", this["JST"]["timelineinfo"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "			<a class=\"btn\" href=\""
    + container.escapeExpression(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.btntxt || (depth0 != null ? depth0.btntxt : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btntxt","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"item text-center "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-period=\""
    + alias4(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"period","hash":{},"data":data}) : helper)))
    + "\" data-period-length=\""
    + alias4(((helper = (helper = helpers.periodlength || (depth0 != null ? depth0.periodlength : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"periodlength","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"inner-item\">\n		<h3>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n		<h6>"
    + ((stack1 = ((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h6>\n		"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.link : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n</div>";
},"useData":true}));

this["JST"]["cookienoticebar"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"cookieNoticeBar\">\n    <div class=\"container\">\n        <div class=\"row\">\n            <div class=\"col-md-6 text-left\">\n                <h4>Cookie Notice</h4>\n                <p>This site uses cookies as described in our <a href=\"#cookie-policy\">Cookie Policy</a>. To view cookies served on this website, please review our <a href=\"#cookie-annex\">Cookie Annex</a>. Please continue to use our website if you agree to our use of cookies.</p>\n            </div>\n            <div class=\"col-md-6 btn-wrap\">\n                <a class=\"btn btn-secondary text-uppercase cookieNoticeAgree\">I Agree</a>\n                <a class=\"btn btn-secondary text-uppercase\" href=\"#cookie-policy\">About Our Use of Cookies</a>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["dashboardlinks"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "		<a href=\""
    + alias4(((helper = (helper = helpers.link || (depth0 != null ? depth0.link : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\">\n			<div class=\"linkText\">\n                <img class=\"mobile-nav-icons\" src=\""
    + alias4(((helper = (helper = helpers.icon || (depth0 != null ? depth0.icon : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data}) : helper)))
    + "\" width=\"30\" style=\"margin-right: 5px;\">\n				"
    + alias4(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data}) : helper)))
    + "\n			</div>\n		</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"dashboardLinks\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.links : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

this["JST"]["dashboardmenu"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "					<a href=\"/logout.aspx\" class=\"btn logoutBtn retire-modal-trigger\">Logout</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "						<a href=\"#login\" class=\"btn loginBtn retire-modal-trigger\">Login</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "						<a href=\"#register\" class=\"btn registerBtn btn-secondary retire-modal-trigger\"><span class=\"dayCount\"></span> days to register</a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"daysLeft countText\"></div>\n                    <h3 class=\"daytext\">Days To Submit</h3>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"daysLeft countText\"></div>\n                        <h3 class=\"daytext\"><span class=\"dayLeftCountWord\">Days</span> To Submit</h3>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"daysCount countText\"></div>\n                        <h3 class=\"daytext\"><span class=\"dayCountWord\">Days</span> To Register</h3>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "					<a href=\"/pw\" class=\"btn\">Return to Prizeware</a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "						<a href=\"#login\" class=\"btn loginBtn\">Login</a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "						<a href=\"#register\" class=\"btn registerBtn\">Register Now</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div id=\"mainMenu\" style=\"display:none;\">\n    <div id=\"centerMenuContent\"></div>\n    <div id=\"sideMenu\">\n        <div class=\"mobBack visible-xs\">\n            <i class=\"jbc-left-arrow\"></i>\n        </div>\n        <div class=\"menuWrap\">\n            <div id=\"sidebar\"></div>\n            <div class=\"mobile-login\">\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || alias2).call(alias1,"loggedin",{"name":"checkUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "			</div>\n            <div class=\"dashboard-footer-menu\">\n				<ul>\n					<li><a href=\"#privacy-policy\">Privacy Policy</a></li>\n					<li><a href=\"#terms-conditions\">Terms &amp; Conditions</a></li>\n					<li><a href=\"#designing-the-genh-challenge\">Contact</a></li>\n				</ul>\n				\n			</div>\n        </div>\n        \n        <div class=\"closeWrap\">\n            <i class=\"icon-icon-close\"></i>\n        </div>\n    </div>\n    <div id=\"actionMenu\">\n    	<!--<div class=\"inner layout-vertical-center\">\n			<div class=\"mobBack visible-xs\">\n				<i class=\"jbc-left-arrow\"></i>\n			</div>\n			<div class=\"validationMessage\"></div>\n    		<div class=\"actionContent\"></div>\n			<div class=\"daysPromo\">\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || alias2).call(alias1,"loggedin",{"name":"checkUser","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "				<div class=\"actionFooter\">\n"
    + ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || alias2).call(alias1,"loggedin",{"name":"checkUser","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "				</div>\n			</div>\n        </div>-->\n    </div>\n</div>";
},"useData":true});

this["JST"]["faqcards"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = "";

  stack1 = ((helper = (helper = helpers.desktop_width || (depth0 != null ? depth0.desktop_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_width","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.phone_width || (depth0 != null ? depth0.phone_width : depth0)) != null ? helper : alias2),(options={"name":"phone_width","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.phone_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n\n\n			<div class=\"col-sm-12 col-md-4 qa-card\">\n				<div class=\"inner-col layout-vertical-center\">\n					<h3>"
    + ((stack1 = ((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n					<div class=\"expand-bg\"></div>\n					<div class=\"hidden innerSwap\">\n            <h3 class=\"mobile-question\">"
    + ((stack1 = ((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n						"
    + ((stack1 = ((helper = (helper = helpers.answer || (depth0 != null ? depth0.answer : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"answer","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n					</div>\n				</div>\n			</div>\n\n\n\n";
  stack1 = ((helper = (helper = helpers.phone_width || (depth0 != null ? depth0.phone_width : depth0)) != null ? helper : alias2),(options={"name":"phone_width","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.phone_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.desktop_width || (depth0 != null ? depth0.desktop_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_width","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),3,{"name":"rowOpen","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"row\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),2,{"name":"rowOpen","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),2,{"name":"rowClose","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "		</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),3,{"name":"rowClose","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row-wrap expanderGrid\">\n	<div class=\"container\">\n\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n\n	<div class=\"row detailRow\">\n		<div class=\"expanderContent\"></div>\n		<div class=\"expanderNav\">\n			<span class=\"expander-prev\">\n				<i class=\"left-arrow prev material-icons\">play_arrow</i>\n			</span>\n			<span class=\"expander-next\">\n				<i class=\"right-arrow next material-icons\">play_arrow</i>\n			</span>\n			<i class=\"close material-icons\">close</i>\n		</div>\n	</div>\n	</div>\n</div>\n";
},"useData":true});

this["JST"]["finalistspage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.finalistgroup,depth0,{"name":"finalistgroup","data":data,"indent":"         ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.finalistinfogroup,depth0,{"name":"finalistinfogroup","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"judgeTypeBar\" style=\"opacity:0;\" data-0=\"opacity:0;bottom:0px;\" data-600=\"opacity:0;\" data-700=\"opacity:1;\" data-end=\"bottom:60px;\" data-60-end=\"bottom:0px;\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.finalistsGroup : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <span class=\""
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(data && data.index),0,{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "enabled";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"finalists\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.finalistsGroup : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div id=\"finalistDetail\">\n	<div class=\"container\">\n	\n	</div>\n    <div class=\"container\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.finalistsGroup : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.showTypeBar : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["JST"]["footer"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"footer-wrap\">	\n	<div class=\"container-fluid\">	\n		<div class=\"col-md-7\">\n			<div class=\"footer-left retired-load_footer_note\">\n				<p>This Mission Innovation Prize Programme Logo is owned by Her Majesty the Queen in Right of Canada, as represented by the Minister of Natural Resources, 2017. Mission Innovation governments may use the Logo for the purposes of promoting the Mission Innovation Prize Programme provided the following text accompanies its use: “© Her Majesty the Queen in right of Canada (2017.) The Mission Innovation Prize Programme is a joint initiative of Mission Innovation member governments.\" All other uses are prohibited without the express written consent from Natural Resources Canada.</p>\n      </div>\n		</div>\n		\n		<div class=\"col-md-5\">\n			<div class=\"footer-right text-right\">\n					<div class=\"legalLinks\">\n					<a href=\"#privacy-policy\">Privacy Policy</a>\n					<a href=\"#terms-conditions\">Terms &amp; Conditions</a>\n					<a href=\"mailto:questions@michampions.net?subject=Question about MI Champions\">Contact</a>\n				</div>\n				<div class=\"copyright\">\n                    Designed by <a href=\"http://commonpool.org/\" target=\"_blank\">Common Pool</a>; powered by <a href=\"https://www.rampit.com/\" target=\"_blank\">Rampit</a>. © 2018 Rampit.\n				</div>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});

this["JST"]["forgotpassword"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<i class=\"material-icons register-login-forms-close\">close</i>\n\n<div class=\"formmessage layout-vertical-center\">\n	<div class=\"inner\">\n		<div class=\"modal-title\">\n			Forgot\n		</div>\n		<div class=\"contentModalWrap\">\n			<p>Please enter the email you registered with and then click send link.</p>\n		</div>\n	</div>\n</div>\n<div id=\"forgotPassword\" class=\"modal-container layout-vertical-center\">\n	<div class=\"inner\">\n		<!--<div class=\"modal-close icon-icon-close\"></div>\n		<div class=\"modal-title\">Forgot Password</div>-->\n		<div class=\"form message\" role=\"form\">\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"email\" type=\"email\" class=\"form-control firstLoad\">\n						<span class=\"floating-label\">Email</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<button id=\"submitForgotBtn\" class=\"submitForgotBtn btn\" type=\"submit\" disabled=\"disabled\">Reset</button>\n				</div>\n			</div>\n			<div class=\"register_message\" style=\"display: none\">\n				<div class=\"col-xs-12\"></div>\n			</div>\n		</div>\n	</div>\n	\n	<div class=\"toggle-screen\">\n		<a href=\"#login\" class=\"modal-switch btn btn-tertiary\">I want to Login</a>\n	</div>\n\n</div>\n\n";
},"useData":true});

this["JST"]["judgesample"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.bsRow || (depth0 && depth0.bsRow) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),8,depth0,{"name":"bsRow","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"item\">\n"
    + ((stack1 = (helpers.bsRow || (depth0 && depth0.bsRow) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),4,depth0,{"name":"bsRow","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"row\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgebox,depth0,{"name":"judgebox","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.bsRow || (depth0 && depth0.bsRow) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),4,depth0,{"name":"bsRow","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "<div class=\"judge-sample-carousel carousel slide\" data-ride=\"carousel\">\n  <div class=\"carousel-inner\">\n\n";
  stack1 = ((helper = (helper = helpers.desktop_width || (depth0 != null ? depth0.desktop_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_width","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.phone_width || (depth0 != null ? depth0.phone_width : depth0)) != null ? helper : alias2),(options={"name":"phone_width","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.phone_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n\n\n  </div>\n\n  <div class=\"caro-nav-container text-right\">\n    <a class=\"left carousel-control\" data-slide=\"prev\" data-target=\".judge-sample-carousel\"><i class=\"left-arrow prev material-icons\">play_arrow</i></a>\n    <div class=\"caro-count\">\n      <ol class=\"count-active carousel-indicators\" type=\"1\"></ol>\n      <span class=\"count-of\">of</span>\n      <span class=\"count\"></span>\n\n    </div>\n    <a class=\"right carousel-control\" data-slide=\"next\" data-target=\".judge-sample-carousel\"><i class=\"left-arrow prev material-icons\">play_arrow</i></a>\n  </div>\n\n</div>\n";
},"usePartial":true,"useData":true});

this["JST"]["judgespage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgegroup,depth0,{"name":"judgegroup","data":data,"indent":"         ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.judgeinfogroup,depth0,{"name":"judgeinfogroup","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"judgeTypeBar\" style=\"opacity:0;\" data-0=\"opacity:0;bottom:0px;\" data-600=\"opacity:0;\" data-700=\"opacity:1;\" data-end=\"bottom:60px;\" data-60-end=\"bottom:0px;\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <span class=\""
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(data && data.index),0,{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-judgegroup=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "enabled";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"judges\">\n    <!-- peer to peer -->\n    <article class=\"judgeGroup border-box\" data-judgegroup=\"Peer to Peer Review\" data-title=\"Peer to Peer Review\">\n		<div class=\"container\">\n			<div class=\"judgeIcon visible-xs\"></div>\n			<div class=\"row\">\n				<div class=\"col-sm-12 col-md-10 col-md-offset-1\">\n					<h3 class=\"retired-load_judges_g2_h3\">Peer to Peer Review</h3>\n				</div>\n			</div>\n			<div class=\"row\">\n				<div class=\"col-sm-12 col-md-10 col-md-offset-1 textColumn retired-load_judges_g2_content\">\n					<p>During Peer-to-Peer Review, each participant will score and comment upon five applications, using the same scoring criteria that the Evaluation Panel will use.</p>\n					<p>Scores will be calculated using a normalization algorithm that ensures a level playing field for everyone. In addition to scoring each submission on the four criteria, you will also review the question responses and provide a final Grade Score, ranging between 0-100, that provides an overall assessment of quality for the entire submission. We ask that you carefully review the submissions assigned to you and provide meaningful feedback.</p>\n					<p>The Peer-to-Peer Review will result in a rank-order of all submissions. The top applications from each participating country will be reviewed by the Evaluation Panel.</p>\n				</div>\n			</div>\n		</div>\n	</article>\n    \n    \n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div id=\"judgesDetail\">\n	<div class=\"container\">\n	\n	</div>\n    <div class=\"container\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.judgeGroups : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.config : depth0)) != null ? stack1.showTypeBar : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});

this["JST"]["lastcard"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"container-fluid\">\n	<div class=\"row\">\n		\n		<div class=\"col-sm-12 col-md-7 lastcard-left layout-vertical-center\">\n			<div class=\"content-inner\">\n				<div class=\"retired-load_all_last_card\">\n					<h3>Want to be recognized for <br /> your work in clean energy?</h3>\n				</div>\n				<h4>Only <span class=\"dayCount\"></span> days left to Register.</h4>\n				<a class=\"btn btn-secondary retire-modal-trigger\" href=\"#register\">Register Now</a>\n			</div>\n		</div>\n      \n        \n\n		<div class=\"col-sm-12 col-md-5 lastcard-right\">\n			<div class=\"content-inner layout-vertical-center\">\n				<div>\n					<div class=\"retired-load_last_card_about\">\n						<h3>About <br /> Mission Innovation</h3>\n						<p>Launched at the Paris Climate Conference in 2015, Mission Innovation (MI) is a global initiative of 22 countries and the European Commission, on behalf of the European Union, seeking to dramatically accelerate global clean energy innovation and make clean energy widely affordable and reliable.</p>\n						<p>MI countries represent 60% of the world’s population, 70% of GDP, and 80% of government investment in clean energy research. MI countries are taking action to double their public investments in clean energy R&amp;D over five years while encouraging collaboration among partner nations, sharing information, and coordinating with businesses and investors.</p>\n          </div>\n					<a class=\"btn btn-secondary\" href=\"http://www.mission-innovation.net/\" target=\"_blank\">Visit Website</a>\n				</div>\n			</div>\n			<div class=\"bottom-logo layout-vertical-center\">\n				<div class=\"logo-container\">\n					<img src=\"img/logo-white.png\" class=\"last-card-logo\"/>\n				</div>\n			</div>\n		</div>\n\n    </div>\n</div>\n\n";
},"useData":true});

this["JST"]["login"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<i class=\"material-icons register-login-forms-close\">close</i>\n\n<div class=\"formmessage layout-vertical-center\">\n	<div class=\"inner\">\n		<div class=\"modal-title\">\n			Login\n		</div>\n		<div class=\"contentModalWrap\">\n			<p>To complete/view your application, please login.</p>\n		</div>\n	</div>\n</div>\n<div class=\"validationMessage\"></div>\n<div id=\"login\" class=\"modal-container layout-vertical-center\">\n	<div class=\"inner\">\n		<!--<div class=\"modal-close icon-icon-close\"></div>\n		<div class=\"modal-title\">Login</div>-->\n		<div class=\"form login\" role=\"form\">\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"email\" type=\"email\" id=\"\" placeholder=\"\" class=\"form-control firstLoad\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"floating-label\">Email</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"password\" type=\"password\" id=\"\" placeholder=\"\" class=\"form-control\">\n						<span class=\"floating-label\">Password</span>\n					</div>\n				</div>\n			</div>\n		\n			<div class=\"row modal-row-pad social-login-row\">\n				<span class=\"subtext above-social-login\">\n					<a href=\"#register\">Don't have an account?</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href=\"#forgot\">Forgot Password?</a>\n				</span>\n			\n			\n			\n			</div>\n		\n			<div class=\"row modal-row-pad below-social-login-btn\">\n				<div class=\"col-xs-12\">\n					<button id=\"signinButton\" class=\"signinButton btn btn-tertiary\" type=\"submit\" disabled=\"disabled\">Login</button>\n				</div>\n			</div>\n		</div>\n	</div>\n	\n	<div class=\"socialLogin\">\n		<div class=\"row modal-row-pad\">\n			<div class=\"socialLinks\">\n				<a id=\"facebookButton\" href=\"#\">\n					<span class=\"bg\"></span>\n					<span class=\"txt hidden-sm\">Login with Facebook</span>\n				</a>\n				<a id=\"googleButton\" href=\"#\">\n					<span class=\"bg\"></span>\n					<span class=\"txt hidden-sm\">Login with Google</span>\n				</a>\n			\n			</div>\n		</div>\n	</div>\n</div>\n\n";
},"useData":true});

this["JST"]["navbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"loggedin",{"name":"checkUser","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                            <span class=\"daysToRegister hidden-xs\">\n							<a href=\"/pw\" class=\"btn solid navreg retire-modal-trigger\">Return to Dashboard</a>\n							<a class=\"btn solid modal-close\">\n								<span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n							</a>\n						</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.checkUser || (depth0 && depth0.checkUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"registered",{"name":"checkUser","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "                                <span class=\"daysToRegister hidden-xs\">\n							<a href=\"#login\" class=\"btn solid navreg retire-modal-trigger\">Login</a>\n							<a class=\"btn solid modal-close\">\n								<span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n							</a>\n						</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                                <span class=\"daysToRegister hidden-xs\">\n								<a href=\"#register\" class=\"btn solid navreg retire-modal-trigger\">\n									<span class=\"dayCount\"></span>&#160;\n									<span><span class=\"dayCountWord\">days</span> to <strong>Register</strong></span>\n								</a>\n								<a class=\"btn solid modal-close\">\n									<span>Back To <strong class=\"activePageTitle\">Home</strong></span>\n								</a>\n							</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"btn retire-modal-trigger\" href=\"#nominate\" style=\"height:48px;margin-right:-1px;\">Register</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<nav id=\"mainNavbar\" class=\"navbar navbar-default\" role=\"navigation\">\n    <div class=\"container navbar-container\">\n		<div class=\"row layout-vertical-center\">\n		\n			<div class=\"col-xs-6 col-sm-6 col-md-6\">\n				<div class=\"navbar-header layout-vertical-center\">\n				<a class=\"navbar-brand anim\" href=\"/#home\"></a>\n				<a class=\"navbar-brand-sub\" href=\"/#home\"></a>\n			</div>\n        	</div>\n\n			<div class=\"col-xs-6 col-sm-6 col-md-6\">\n				<div class=\"dashboard-menu-container\">\n					<div class=\"dashboardToggle dropdown\">\n						<a href=\"\">\n							<div class=\"icon hamburger-to-close\">\n								<div class=\"hamburger-middle\"></div>\n							</div>\n							<span class=\"dashboard-menu-text\">Menu</span>\n						</a>\n					</div>\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showRegisterLogin : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "				</div>\n			</div>\n\n		</div>\n    </div>\n</nav>";
},"useData":true});

this["JST"]["newsmenu"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"row newsMenuBox\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n	<div class=\"col-xs-12\">\n		<h5>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h5>\n		<div class=\"postInfo\">\n			<p>\n				<span>"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n			</p>\n		</div>\n		<div class=\"postDescription\">\n			<p>"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + " <a class=\"readMoreBtn\" href=\"updates#"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"><i class=\"icon-arrow-right\"></i></a></p>\n		</div>\n	</div>\n</div>";
},"useData":true});

this["JST"]["newspost"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\" class=\"hidden-sm hidden-xs\" />\n            <div class=\"postInfoWrap\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<article class=\"row newsbox col-md-12\" data-pagenav=\"true\" data-news=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-title=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"text-left news-inner border-box\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.image : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <h2>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n            <div class=\"postInfo\">\n            <div class=\"sml-border\"></div>\n                <p>\n                    <span>"
    + alias4(((helper = (helper = helpers.date || (depth0 != null ? depth0.date : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data}) : helper)))
    + "</span>\n                </p>\n            <div class=\"sml-border\"></div>\n            </div>\n            <a class=\"btn readMoreBtn\">Read</a>\n            <!--<div class=\"postDescription\">\n                <p>"
    + ((stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n            -->\n            <div class=\"postContent\">\n                 <p>\n                    <span class=\"postAuthor\">"
    + alias4(((helper = (helper = helpers.author || (depth0 != null ? depth0.author : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"author","hash":{},"data":data}) : helper)))
    + "</span>\n                </p>\n                <p>"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n            </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.image : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</article>";
},"useData":true});

this["JST"]["processstepdetail"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "        <a href=\""
    + ((stack1 = ((helper = (helper = helpers.btnLink || (depth0 != null ? depth0.btnLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnLink","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\" class=\"btn\">"
    + ((stack1 = ((helper = (helper = helpers.btnText || (depth0 != null ? depth0.btnText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnText","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"step-card-content\" data-processid=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div id=\"process-number-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"process-number-container\">\n        <span class=\"process-number layout-vertical-center\">"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "</span>\n    </div>\n    <h3>"
    + ((stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h3>\n    <span class=\"process-date\">"
    + ((stack1 = ((helper = (helper = helpers.dates || (depth0 != null ? depth0.dates : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"dates","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n    <p>"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.btnText : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});

this["JST"]["processsteps"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"process-card wow fadeInUp\" data-processid=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-wow-duration=\".4s\" data-wow-delay=\"."
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "s\">\n                <span class=\"process-number\">"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "</span>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"container process-container\">\n    <div class=\"row cards-container\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.steps : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"process-content-container\">\n        <div class=\"process-content-inner\"></div>\n        <div class=\"process-step-next-wrap\">\n        	<i class=\"left-arrow prev material-icons previous-card\">play_arrow</i>\n            <span class=\"counts\"><span class=\"current\"></span><span class=\"count-divide\">of</span><span class=\"total\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.steps : depth0)) != null ? stack1.length : stack1), depth0))
    + "</span></span>\n            <i class=\"right-arrow next material-icons next-card\">play_arrow</i>\n        </div>\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["register"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.fname || (depth0 != null ? depth0.fname : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fname","hash":{},"data":data}) : helper)))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.lname || (depth0 != null ? depth0.lname : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lname","hash":{},"data":data}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<i class=\"material-icons register-login-forms-close\">close</i>\n\n<div class=\"formmessage layout-vertical-center\">\n	<div class=\"inner\">\n		<div class=\"modal-title\">\n			Register\n		</div>\n		<div class=\"contentModalWrap\">\n			<p>Please register in order to participate</p>\n		</div>\n	</div>\n</div>\n<div class=\"validationMessage\"></div>\n<div id=\"register\" class=\"modal-container layout-vertical-center\">\n	<div class=\"inner\">\n		<!--<div class=\"modal-close icon-icon-close\"></div>\n		<div class=\"modal-title\">Register</div>-->\n		<div class=\"form\" role=\"form\">\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"fname\" type=\"text\" id=\"\" placeholder=\"\" class=\"form-control firstLoad\" required=\"required\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fname : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"floating-label\">First Name</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"lname\" type=\"text\" id=\"\" placeholder=\"\" class=\"form-control\" required=\"required\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lname : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"floating-label\">Last Name</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"email\" type=\"text\" id=\"\" placeholder=\"\" class=\"form-control\" required=\"required\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n						<span class=\"floating-label\">Email</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<div class=\"form-group\">\n						<input name=\"password\" type=\"password\" id=\"\" placeholder=\"\" class=\"form-control\" required=\"required\">\n						<span class=\"floating-label\">Password</span>\n					</div>\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\" >\n					<div class=\"form-group checkGroup\">\n					  <div class=\"form-control\">\n						<input name=\"chkAgree\" id=\"chkAgree\" type=\"checkbox\" required=\"required\">\n						<label for=\"chkAgree\"></label>\n						<span>\n							I accept the <a href=\"/#terms-conditions\">Terms & Conditions</a>, and I agree that my personal data may be processed by Rampit in order to be a part of the Mission Innovation Champions program.\n						</span>\n					  </div>\n					</div>\n					<!-- Hidden Fields -->\n					<input name=\"type\" type=\"hidden\" />\n				</div>\n			</div>\n			<div class=\"row modal-row-pad\">\n				<div class=\"col-xs-12\">\n					<button id=\"submitRegisterBtn\" class=\"submitRegisterBtn btn btn-tertiary\" type=\"submit\" disabled=\"disabled\">Register</button> <span class=\"toggle-span\">or</span> <a href=\"#login\" class=\"btn btn-tertiary modal-switch\">Login</a>\n				</div>\n			</div>\n			<div class=\"register_message\" style=\"display: none\">\n				<div class=\"col-xs-12\">\n				</div>\n			</div>\n		</div>\n    </div>\n</div>\n";
},"useData":true});

this["JST"]["resetpassword"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "value=\""
    + container.escapeExpression(((helper = (helper = helpers.regEmail || (depth0 != null ? depth0.regEmail : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"regEmail","hash":{},"data":data}) : helper)))
    + "\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"formmessage layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"modal-title\">\n            Reset\n        </div>\n        <div class=\"contentModalWrap\">\n            <p>Please reset your password.</p>\n        </div>\n    </div>\n</div>\n<div id=\"resetPassword\" class=\"modal-container layout-vertical-center\">\n    <div class=\"inner\">\n        <div class=\"form message\" role=\"form\">\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"email\" type=\"email\" id=\"\" class=\"form-control firstLoad\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.regEmail : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <span class=\"floating-label\">Email</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <div class=\"form-group\">\n                        <input name=\"password\" type=\"password\" class=\"form-control\" autocomplete=\"false\">\n                        <span class=\"floating-label\">Password</span>\n                    </div>\n                </div>\n            </div>\n            <div class=\"row\">\n                <div class=\"col-xs-12\">\n                    <button id=\"resetPasswordButton\" class=\"btn btn-tertiary\" type=\"submit\" disabled=\"disabled\">Reset Password</button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["scoring"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "						<div class=\"tick tickTop\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\"></div>\n						<div class=\"tick tickBottom\" style=\"left:"
    + alias3((helpers.tickLeft || (depth0 && depth0.tickLeft) || alias2).call(alias1,(data && data.index),((stack1 = (depths[1] != null ? depths[1].items : depths[1])) != null ? stack1.length : stack1),{"name":"tickLeft","hash":{},"data":data}))
    + ";\">"
    + alias3(((helper = (helper = helpers.score || (depth0 != null ? depth0.score : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"score","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"scoreQuestion section pushSection solid-gray-bg\" data-title=\"\">\n	<div class=\"container\">\n		<div class=\"row center-height\">\n			<a class=\"sbtn scoreCommentBtn primaryBlankBtn hidden-xs\">Leave Comments</a>\n			<h3 class=\"\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " <span class=\"scoreRange\">(0-"
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + ")</span></h3>\n			<div class=\"text-center\">\n				<div class=\"helpText\"><div class=\"inner\"><h5>Help</h5>"
    + alias4(((helper = (helper = helpers.helpText || (depth0 != null ? depth0.helpText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpText","hash":{},"data":data}) : helper)))
    + "</div></div>\n				<div class=\"commentsText text-center\">\n					<textarea placeholder=\"Comments (minimum 50 characters): Explain your score and provide feedback to the participant.\"></textarea>\n					<div class=\"hidden-xs\">\n						<a class=\"btn\" href=\"#\">Submit Comments</a>\n					</div>\n				</div>\n			</div>\n			<div class=\"scoreHeader\">\n				<div class=\"scoreQuestion\">\n					<span class=\"question-text\">"
    + alias4(((helper = (helper = helpers.question || (depth0 != null ? depth0.question : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"question","hash":{},"data":data}) : helper)))
    + "</span>\n					\n					<div class=\"icon-icon-close\"></div>\n				</div>\n			</div>\n			<div class=\"scoreDescription\">\n				<span></span>\n			</div>\n			<div class=\"scoreValue\">\n				<span class=\"valfollow\"></span>\n				<span class=\"valBorderLeft hidden-xs\"></span>\n				<span class=\"valBorderRight hidden-xs\"></span>\n			</div>\n			<div class=\"scoreSliderContainer\">\n				<div class=\"sliderTicks\">\n			    	<span class=\"visible-xs anchorLow\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			    	<span class=\"visible-xs anchorHigh\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n				</div>\n				<span class=\"traitEndL\">"
    + alias4(((helper = (helper = helpers.anchorLow || (depth0 != null ? depth0.anchorLow : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorLow","hash":{},"data":data}) : helper)))
    + "</span>\n				<div class=\"scoreSlider\">\n					<input id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" class=\"scoreSliderInput\" data-slider-id=\"ex"
    + alias4(((helper = (helper = helpers.index || (depth0 != null ? depth0.index : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "Slider\" type=\"text\" data-slider-min=\"0\" data-slider-max=\""
    + alias4((helpers.minusOne || (depth0 && depth0.minusOne) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.items : depth0)) != null ? stack1.length : stack1),{"name":"minusOne","hash":{},"data":data}))
    + "\" data-slider-step=\"0.1\" data-slider-value=\""
    + alias4(((helper = (helper = helpers.initScore || (depth0 != null ? depth0.initScore : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"initScore","hash":{},"data":data}) : helper)))
    + "\"/>\n				</div>\n				<span class=\"traitEndR\">"
    + alias4(((helper = (helper = helpers.anchorHigh || (depth0 != null ? depth0.anchorHigh : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"anchorHigh","hash":{},"data":data}) : helper)))
    + "</span>\n			</div>\n\n		</div>\n		<!--<a class=\"btn scoreCommentBtn primaryBlankBtn hidden-xs hidden-sm \">Leave Comments</a>-->\n	</div>\n</div>";
},"useData":true,"useDepths":true});

this["JST"]["sidebar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.sidebaritem,depth0,{"name":"sidebaritem","data":data,"indent":"\t\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.menuItems : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true});

this["JST"]["sidenav"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sideNav\">\n    <span class=\"prev\">\n    	<i class=\"material-icons layout-horizontal-flip\">play_arrow</i>\n    </span>\n    <span class=\"counter\">\n        	<div class=\"counter-inner\">\n            	<div class=\"currentCount\">1</div>\n            	<span class=\"divider\"></span>\n            	<div class=\"totalCount\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.records : depth0)) != null ? stack1.length : stack1), depth0))
    + "</div>\n            </div>\n        </span>\n    <span class=\"next\">\n    	<i class=\"material-icons\">play_arrow</i>\n    </span>\n</div>";
},"useData":true});

this["JST"]["social"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li><div class=\"socialicon socialicon-"
    + alias4(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"key","hash":{},"data":data}) : helper)))
    + "\"></div><a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a></li>";
},"useData":true});

this["JST"]["sponsorsliderbar"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = "";

  stack1 = ((helper = (helper = helpers.desktop_width || (depth0 != null ? depth0.desktop_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_width","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.phone_width || (depth0 != null ? depth0.phone_width : depth0)) != null ? helper : alias2),(options={"name":"phone_width","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.phone_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                    <div class=\"col-sm-12 col-md-3 sponsor-block-1 layout-vertical-center\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "\n                    </div>\n\n";
  stack1 = ((helper = (helper = helpers.phone_width || (depth0 != null ? depth0.phone_width : depth0)) != null ? helper : alias2),(options={"name":"phone_width","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.phone_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.desktop_width || (depth0 != null ? depth0.desktop_width : depth0)) != null ? helper : alias2),(options={"name":"desktop_width","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.desktop_width) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),4,{"name":"rowOpen","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"item"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),0,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                        <div class=\"row text-center\">\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " active";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowOpen || (depth0 && depth0.rowOpen) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),1,{"name":"rowOpen","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                        <div class=\"item"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(data && data.index),0,{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n                        <div class=\"row text-center\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n                                <span class=\"flag-wrap\"> <img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"sponsor-block-img\"/></span>\n                                <span class=\"sponsor-title\">"
    + ((stack1 = ((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n                            </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                            <span class=\"no-link "
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n                                <span class=\"flag-wrap\"> <img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"sponsor-block-img\"/></span>\n                                <span class=\"sponsor-title\">"
    + ((stack1 = ((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n                            </span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),1,{"name":"rowClose","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "                        </div></div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.rowClose || (depth0 && depth0.rowClose) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),4,{"name":"rowClose","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"sponsorRow row\">\n    <div class=\"col-xs-6 col-sm-6 col-md-3 text-center supportCol\">\n        <span class=\"text-uppercase\">Supported By</span>\n        <img src=\"img/support-logo.png\"/>\n    </div>\n    <div class=\"carousel slide\" data-ride=\"carousel\">\n        <div class=\"col-xs-1 col-sm-1 col-md-1 text-center\"><a class=\"left carousel-control\" data-slide=\"prev\"\n                                                               data-target=\".sponsorRow .carousel\"><i\n                class=\"left-arrow prev material-icons\">play_arrow</i></a></div>\n\n        <div class=\"col-xs-4 col-sm-4 col-md-7\">\n            <span class=\"carousel-title hidden-xs\">Participating Countries</span>\n            <div class=\"carousel-inner\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n\n        <div class=\"col-xs-1 col-sm-1 col-md-1 text-center\">\n            <a class=\"right carousel-control\" data-slide=\"next\" data-target=\".sponsorRow .carousel\">\n                <i class=\"left-arrow prev material-icons\">play_arrow</i>\n            </a>\n        </div>\n\n    </div>\n</div>";
},"useData":true});

this["JST"]["timeline"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials.timelineinfo,depth0,{"name":"timelineinfo","data":data,"indent":"\t\t\t\t\t ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "		\n			<div class=\"timelinePoint "
    + ((stack1 = helpers["if"].call(alias1,(data && data.first),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(data && data.last),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n				data-toggle=\"tooltip\" data-placement=\"top\" data-html=\"true\" data-trigger=\"hover\" title=\"<span>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span><span>"
    + ((stack1 = ((helper = (helper = helpers.balloonsubtitle || (depth0 != null ? depth0.balloonsubtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"balloonsubtitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\"\n				style=\"left:"
    + alias4((helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(depth0 != null ? depth0.timestamp : depth0),"-",(depths[1] != null ? depths[1].start : depths[1]),{"name":"math","hash":{},"data":data}),"*",(depths[1] != null ? depths[1].msIncrement : depths[1]),{"name":"math","hash":{},"data":data}))
    + "%;\">\n			</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "            <div class=\"connectLine\" data-period=\""
    + container.escapeExpression(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"period","hash":{},"data":data}) : helper)))
    + "\"\n                 style=\"left:-1028px;width:1000px\">\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "				<div class=\"connectLine\" data-period=\""
    + alias3(((helper = (helper = helpers.period || (depth0 != null ? depth0.period : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"period","hash":{},"data":data}) : helper)))
    + "\" style=\"\n					left: "
    + alias3((helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(helpers.prev || (depth0 && depth0.prev) || alias2).call(alias1,(depths[1] != null ? depths[1].timelinePoints : depths[1]),(data && data.index),"timestamp",{"name":"prev","hash":{},"data":data}),"-",(depths[1] != null ? depths[1].start : depths[1]),{"name":"math","hash":{},"data":data}),"*",(depths[1] != null ? depths[1].msIncrement : depths[1]),{"name":"math","hash":{},"data":data}))
    + "%;\n					width:calc("
    + alias3((helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(helpers.math || (depth0 && depth0.math) || alias2).call(alias1,(depth0 != null ? depth0.timestamp : depth0),"-",(helpers.prev || (depth0 && depth0.prev) || alias2).call(alias1,(depths[1] != null ? depths[1].timelinePoints : depths[1]),(data && data.index),"timestamp",{"name":"prev","hash":{},"data":data}),{"name":"math","hash":{},"data":data}),"*",(depths[1] != null ? depths[1].msIncrement : depths[1]),{"name":"math","hash":{},"data":data}))
    + "%);\n					\">\n				</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "first active";
},"10":function(container,depth0,helpers,partials,data) {
    return "last";
},"12":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"timeline-toggle\">\n            <div class=\"detail\">\n                <i class=\"material-icons\">list</i> detail view\n            </div>\n            <div class=\"overview\">\n                <i class=\"material-icons\">apps</i> overview\n            </div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"timelineContainer\">\n	<div id=\"timelineInfo\" class=\"timelineInfoContainer carousel slide layout-vertical-center\" data-interval=\"false\">\n		<div class=\"timelineWrap\">\n			<div class=\"carousel-inner timelineInfo\" role=\"listbox\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</div>\n		</div>\n		<a class=\"timelineNav carousel-control left hidden-xs hidden-sm\" data-slide=\"prev\" data-target=\"#timelineInfo\"><div class=\"inner\"><i class=\"material-icons\">play_arrow</i></div></a>\n		<a class=\"timelineNav carousel-control right hidden-xs hidden-sm\" data-slide=\"next\" data-target=\"#timelineInfo\"><div class=\"inner right\"><i class=\"material-icons\">play_arrow</i></div></a>\n	</div>\n	<div class=\"timeline-bottom\">\n		<div class=\"timeline\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.timelinePoints : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	    </div>\n\n	    <div class=\"timeline-calendar\"><ul></ul></div>\n	    <div class=\"timeline-period\"><h3></h3><h6></h6></div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showOverview : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"usePartial":true,"useData":true,"useDepths":true});

this["JST"]["validationmessage"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-tertiary \" href=\"#login\">Back to Login</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-tertiary \" href=\"#login\">Login</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                    <a class=\"btn btn-tertiary \" href=\"#register\">Register</a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasError : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div id=\"form-message\" class=\"modal-container layout-vertical-center\">\n	<div class=\"inner\">\n		\n		<div class=\"contentModalWrap\">\n			<p>"
    + ((stack1 = ((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</p>\n"
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"login",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"register",{"name":"ifCond","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"forgotPassword",{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifCond || (depth0 && depth0.ifCond) || alias2).call(alias1,(depth0 != null ? depth0.originForm : depth0),"resetPassword",{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n    </div>\n</div>\n\n";
},"useData":true});

this["JST"]["404"] = Handlebars.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"missingPageInner\" data-bgimage=\"default-bg\">\n    <div class=\"container\">\n        <div class=\"row layout-vertical-center\">\n        	<div class=\"col-xs-12\">\n				<div class=\"retired-load_404_card1 layout-load-card\">\n					<h1>The page you are looking for is no longer here, or maybe it never was here in the first place. In any case, we’re sorry you were sent on this mission far from home.</h1>\n				</div>\n				<a href=\"#home\" class=\"btn\">Take Me Back Home</a>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});

this["JST"]["sharedcard"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"cardWrap border-box\" data-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n        	<!-- news -->\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.category : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "			<!-- end row detail -->\n		</div>\n		<!-- end cardWrap -->\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "				<div class=\"imageWrapLarge news-sidesocial-container hidden-xs\">\n					<span class=\"share\">Share</span>\n					<i class=\"icon-icon-facebook shareSocial\" data-action=\"facebook\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n					<i class=\"icon-icon-twitter shareSocial\" data-action=\"twitter\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n				</div>\n			\n				<div class=\"row detail\">	\n					<div class=\"col-md-offset-1 col-md-11 tplContentContainer\">\n						<div class=\"inner-scrollable news-inner-scrollable\">\n							<div class=\"visible-xs\">\n								<div class=\"imageWrapLarge news-sidesocial-container\">\n									<span class=\"share\">Share</span>\n									<i class=\"icon-icon-facebook shareSocial\" data-action=\"facebook\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n									<i class=\"icon-icon-twitter shareSocial\" data-action=\"twitter\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n								</div>\n							</div>\n							<span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n							<span class=\"cardWrapDate\">"
    + alias4(((helper = (helper = helpers.span || (depth0 != null ? depth0.span : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"span","hash":{},"data":data}) : helper)))
    + "</span>\n							<h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "</h3>\n							<div class=\"content\">"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n						</div>\n					</div>\n				</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "			<!-- judges -->\n				\n				<div class=\"imageWrapLarge hidden-xs hidden-sm\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\"></div>\n			\n				<div class=\"row detail\">	\n					<div class=\"col-md-offset-3 col-md-9 tplContentContainer\">\n						<div class=\"inner-scrollable\">\n							<div class=\"visible-xs visible-sm\">\n								<div class=\"imageWrapLarge\" style=\"background-image:url("
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + ");\"></div>\n							</div>\n							<span class=\"judgeGroupSpan\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n							<h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "</h3>\n							<h4 class=\"title\">"
    + alias4(((helper = (helper = helpers.h4 || (depth0 != null ? depth0.h4 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h4","hash":{},"data":data}) : helper)))
    + "</h3>\n							<div class=\"content\">"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n						</div>\n					</div>\n				</div>\n				\n				\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"tplWrapper\">\n	<div class=\"tplNavWrapper\">\n		<i class=\"icon-close material-icons close\">close</i>\n		<div class=\"sideNavWrap\" data-scrollparent=\".tplWrapper\"></div>\n    </div>\n    <div class=\"cardWrapper\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});

this["JST"]["sharedcardFinalist"] = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"cardWrap finalist border-box\" data-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-pagenav=\"true\">\n        	<!-- news -->\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.category : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "			<!-- end row detail -->\n		</div>\n		<!-- end cardWrap -->\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "				<div class=\"imageWrapLarge news-sidesocial-container hidden-xs\">\n					<span class=\"share\">Share</span>\n					<i class=\"icon-icon-facebook shareSocial\" data-action=\"facebook\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n					<i class=\"icon-icon-twitter shareSocial\" data-action=\"twitter\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n				</div>\n			\n				<div class=\"row detail\">	\n					<div class=\"col-md-offset-1 col-md-11 tplContentContainer\">\n						<div class=\"inner-scrollable news-inner-scrollable\">\n							<div class=\"visible-xs\">\n								<div class=\"imageWrapLarge news-sidesocial-container\">\n									<span class=\"share\">Share</span>\n									<i class=\"icon-icon-facebook shareSocial\" data-action=\"facebook\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n									<i class=\"icon-icon-twitter shareSocial\" data-action=\"twitter\" data-title=\""
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "\"></i>\n								</div>\n							</div>\n							<span class=\"newsTypeTag\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n							<span class=\"cardWrapDate\">"
    + alias4(((helper = (helper = helpers.span || (depth0 != null ? depth0.span : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"span","hash":{},"data":data}) : helper)))
    + "</span>\n							<h3 class=\"name\">"
    + alias4(((helper = (helper = helpers.h3 || (depth0 != null ? depth0.h3 : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"h3","hash":{},"data":data}) : helper)))
    + "</h3>\n							<div class=\"content\">"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n						</div>\n					</div>\n				</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "			<!-- finalists -->\n\n				<div class=\"tplContentContainer\">\n					<div class=\"inner-scrollable\">\n						<div class=\"background-holder\">\n							<div class=\"container\">\n								<div class=\"top-wrap\">\n									<div class=\"img-wrap\">\n										<img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\" alt=\"Champion Image\">\n									</div>\n									<div class=\"txt-wrap\">\n										<h2 class=\"name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h2>\n										<p class=\"title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</p>\n										<h3 class=\"country\">"
    + alias4(((helper = (helper = helpers.country || (depth0 != null ? depth0.country : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"country","hash":{},"data":data}) : helper)))
    + "</h3>\n									</div>\n								</div>\n								<h4 class=\"title\">"
    + alias4(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "</h3>\n								<div class=\"content\">"
    + ((stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.video : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.detailsImage : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n				</div>\n\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return "									<div class=\"video-wrap\">\n										<iframe class=\"finalistVideo\" width=\"100%\" src=\"https://www.youtube.com/embed/"
    + container.escapeExpression(((helper = (helper = helpers.video || (depth0 != null ? depth0.video : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"video","hash":{},"data":data}) : helper)))
    + "?enablejsapi=1&version=3&playerapiid=ytplayer\" frameborder=\"0\" allowfullscreen></iframe>\n									</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "									<div class=\"image-wrap\">\n										<img src=\""
    + alias4(((helper = (helper = helpers.detailsImage || (depth0 != null ? depth0.detailsImage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"detailsImage","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.detailsImageAltText || (depth0 != null ? depth0.detailsImageAltText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"detailsImageAltText","hash":{},"data":data}) : helper)))
    + "\">\n									</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"tplWrapper\">\n	<div class=\"tplNavWrapper\">\n		<div class=\"container container-finalists\">\n			<a href=\"#home\" class=\"finalistsNavLogo\"></a>\n			<i class=\"icon-close material-icons close\">close</i>\n			<div class=\"sideNavWrap\" data-scrollparent=\".tplWrapper\"></div>\n		</div>\n    </div>\n    <div class=\"cardWrapper\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.records : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n</div>\n";
},"useData":true});

if (typeof exports === 'object' && exports) {module.exports = this["JST"];}

return this["JST"];

});
